<template>
  <div class="onboarding">
    <div class="onboarding__sidebar">
      <img
        class="onboarding__logo onboarding__logo--white"
        src="@/assets/img/logonew.png"
        alt="Vibrant Founder Logo"
      />
      <img
        class="onboarding__logo onboarding__logo--orange"
        src="@/assets/img/logo-orange.png"
        alt="Vibrant Creator Logo"
      />
      <div class="onboarding__sideinfo" v-if="activeStep === 1">
        <c-text color="#929ba1">
          Our goal is to help you build a strong business and maximize your
          potential
        </c-text>
        <c-text color="#929ba1"> Think of us as your business partner </c-text>
      </div>
      <br />

      <transition name="fade" mode="out-in">
        <c-stack
          class="onboarding__sidebar__info"
          marginBottom="auto"
          :spacing="4"
        >
          <div
            class="step_div"
            v-for="step in steps"
            :key="step.step"
            :class="activeStep === step.step ? 'active' : ''"
          >
            <span class="step_number">
              {{ step.step }}
            </span>
            <c-text class="step_text">
              {{ step.label }}
            </c-text>
          </div>
        </c-stack>
      </transition>
    </div>
    <div
      class="onboarding__content"
      :class="[
        hasStartedOnboarding
          ? 'onboarding__content--started'
          : 'onboarding__content--welcome',
      ]"
    >
      <div
        v-if="animateToOnboarding && activeCompany"
        style="
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          cursor: pointer;
        "
        @click="goBackToPage"
      >
        <c-text
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderColor="#ef5323"
          borderWidth="1px"
          backgroundColor="#E66521"
          borderRadius="5px"
          color="#fff"
          width="100px"
          height="30px"
        >
          Exit
        </c-text>
      </div>
      <transition name="fade" mode="out-in">
        <!-- <c-stack
          class="onboarding__sidebar__message"
          w="full"
          marginBottom="auto"
          :spacing="3"
        >
          <c-heading as="h4" size="lg"> VIBRANTCREATOR! </c-heading>
          <c-text>
            Your number one business partner! <br />
            It’s free and will always be that that way!
          </c-text>
        </c-stack> -->
        <div
          class="onboarding__sidebar__info"
          marginBottom="auto"
          :spacing="4"
          v-if="activeStep === 1"
        >
          <c-text fontSize="xl" fontWeight="600">
            Welcome {{ user ? user.firstname : 'Name' }}
            {{ user ? user.lastname : 'Name' }}</c-text
          >

          <c-text fontSize="md" fontWeight="400" color="#898989">
            Let us know you more about you.<br />
            This will help us optimise your experience.
          </c-text>
          <br />
          <div @click="startOnboarding">
            <c-text
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderColor="#ef5323"
              borderWidth="1px"
              backgroundColor="#E66521"
              borderRadius="10px"
              cursor="pointer"
              color="#fff"
              width="130px"
              height="40px"
            >
              Start
            </c-text>
          </div>
        </div>
        <form
          v-if="animateToOnboarding && activeStep !== 1"
          @submit.prevent
          class="onboarding__form"
        >
          <div class="onboarding__fields" v-if="activeStep == 2">
            <template v-for="(field, index) in fields">
              <div
                :key="index"
                :ref="'field' + index"
                @click="onFieldClick(field, index)"
                style="margin-bottom: 15px"
              >
                <div class="onboarding__field__header">
                  <c-text class="onboarding__field__label" fontWeight="bold">
                    {{
                      field.submitted
                        ? field.submittedText(field.value)
                        : field.label
                    }}
                  </c-text>
                </div>
                <div class="onboarding__field__content">
                  <transition name="fade">
                    <div class="onboarding__field__content__component">
                      <component
                        v-model="field.value"
                        :field="field"
                        v-bind="field.componentProps"
                        :is="field.component"
                      />
                    </div>
                  </transition>
                </div>
              </div>
            </template>
          </div>
          <div class="onboarding__fields" v-if="activeStep == 3">
            <template v-for="(field, index) in fields2">
              <div
                :key="index"
                :ref="'field' + index"
                @click="onFieldClick(field, index)"
                style="margin-bottom: 15px"
              >
                <div class="onboarding__field__header">
                  <c-text class="onboarding__field__label" fontWeight="bold">
                    {{
                      field.submitted
                        ? field.submittedText(field.value)
                        : field.label
                    }}
                  </c-text>
                </div>
                <div class="onboarding__field__content">
                  <transition name="fade">
                    <div class="onboarding__field__content__component">
                      <component
                        v-model="field.value"
                        :field="field"
                        v-bind="field.componentProps"
                        :is="field.component"
                        @referralDetails="handleReferralDetails"
                      />
                    </div>
                  </transition>
                </div>
              </div>
            </template>
          </div>
          <div class="action_buttons">
            <div>
              <c-button
                h="40px"
                mt="10px"
                borderRadius="lg"
                size="md"
                variant="solid"
                color="grey"
                v-if="activeStep === 3"
                @click="activeStep--"
              >
                Previous step
              </c-button>
            </div>
            <div>
              <c-button
                h="40px"
                mt="10px"
                borderRadius="lg"
                size="md"
                backgroundColor="#E66521"
                color="#fff"
                :disabled="!fieldsFilled"
                v-if="activeStep == 2"
                @click="activeStep++"
              >
                Next Step
              </c-button>
              <c-button
                h="40px"
                mt="10px"
                borderRadius="lg"
                size="lg"
                variant="solid"
                backgroundColor="#EF5323"
                color="#fff"
                :disabled="isCreatingCompany"
                v-if="fieldsFilled && activeStep == 3"
                @click="onFormSubmit"
              >
                <c-spinner
                  v-if="isCreatingCompany"
                  color="#fff"
                  thickness="3px"
                />
                <c-text v-else color="#fff">
                  {{ isNewCompany ? 'Submit' : 'Finish Sign Up' }}
                </c-text>
              </c-button>
            </div>
          </div>
        </form>
        <!-- <c-stack v-else class="welcome__message" :spacing="8">
          <c-heading as="h1" size="xl">
            Welcome
            <c-text ml="5px" color="#1a86d0" as="span">
              {{ user ? user.firstname : 'Name' }}
              {{ user ? user.lastname : 'Name' }}
            </c-text>
          </c-heading>

          <c-button
            borderRadius="lg"
            size="md"
            variant="outline"
            color="#ef5323"
            variant-color="#ef5323"
            h="50px"
            class="welcome__button"
            @click="startOnboarding"
          >
            Now let’s get you started
          </c-button>
        </c-stack> -->
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import TextInput from './components/TextField.vue';
import DepartmentRoleField from './components/DepartmentRoleField.vue';
import OptionSelectField from './components/OptionSelectField.vue';
// import TimelineField from './components/TimelineField.vue';
import SectorField from './components/SectorField.vue';
import LocationField from './components/LocationField.vue';
import CompanyTypeField from './components/CompanyTypeField.vue';
import ReferralCodeField from './components/ReferralCodeField.vue';

import { createCompany, recordCompanyReferral } from '@/services/company.js';
import { updateUser } from '@/services/user';
import DiscoveryField from './components/DiscoveryField.vue';

export default {
  data() {
    return {
      referralCode: null,
      hasStartedOnboarding: false,
      animateToOnboarding: false,
      referralDetails: null,
      fields: [
        {
          name: 'name',
          label: 'Name your company/idea',
          placeholder: 'Please write here…',
          component: TextInput,
          value: null,
          active: true,
          submitted: false,
          isCompanyField: true,
          submittedText: (value) => value,
          required: true,
        },
        {
          name: 'contactEmail',
          label: 'Company email address',
          placeholder: 'business@company.com',
          component: TextInput,
          value: null,
          active: true,
          submitted: false,
          isCompanyField: true,
          submittedText: (value) => value,
          required: true,
        },
        {
          name: 'teams',
          label: 'Describe what you do',
          placeholder: 'Please write here…',
          component: DepartmentRoleField,
          value: null,
          active: false,
          isCompanyField: false,
          submitted: false,
          submittedText: (value) => {
            return `You work in ${value.department} as ${value.role}`;
          },
          required: true,
        },
        {
          name: 'size',
          label: 'How big is your team?',
          placeholder: 'Choose one',
          component: OptionSelectField,
          value: null,
          active: false,
          componentProps: {
            valueKey: 'value',
            label: 'label',
          },
          options: [
            { value: '1 ', label: 'Only me' },
            { value: '2 ', label: '2' },
            { value: '3-6', label: '3-6' },
            { value: '6-15', label: '6-15' },
            { value: '15+', label: '15+' },
          ],
          isCompanyField: true,
          submitted: false,
          submittedText: (value) =>
            value === '1 '
              ? `You work alone`
              : `You're working with ${value} people`,
          required: true,
        },
        {
          name: 'stageId',
          label: 'What business stage are you currently at?',
          placeholder:
            'Please choose the most appropriate stage for your business',
          component: OptionSelectField,
          value: null,
          isCompanyField: true,
          active: false,
          componentProps: {
            valueKey: 'id',
            label: 'name',
          },
          options: this.getStages,
          submitted: false,
          submittedText: (value) =>
            `You're in the ${this.getStageLabel(value)}`,
          required: true,
        },
        // {
        //   name: ['investmentEtaValue', 'investmentEtaMetric'],
        //   label: 'When are you planning to engage investors?',
        //   placeholder:
        //     'Please choose the most appropriate objective for your business',
        //   component: TimelineField,
        //   isCompanyField: true,
        //   value: null,
        //   active: false,
        //   submitted: false,
        //   submittedText: (value) =>
        //     `You're planning to engage investors in ${value.investmentEtaValue} ${value.investmentEtaMetric}`,
        // },
        // {
        //   name: 'investmentEta',
        //   label: 'When are you planning to engage investors?',
        //   placeholder:
        //     'Please choose the most appropriate objective for your business',
        //   component: TimelineField,
        //   isCompanyField: true,
        //   value: null,
        //   active: false,
        //   submitted: false,
        //   submittedText: (value) =>
        //     `You're planning to engage investors on
        //       ${this.$moment(value).format('DD MMM. YYYY')}
        //     `,
        // },
      ],
      fields2: [
        {
          name: 'objective',
          label:
            'What is the next major milestone Vibrantfounder can help you reach?',
          placeholder:
            'Please choose the most appropriate objective for your business',
          component: OptionSelectField,
          componentProps: {
            valueKey: 'value',
            label: 'description',
          },
          value: null,
          isCompanyField: true,
          active: false,
          options: this.getObjectives,
          submitted: false,
          submittedText: (value) =>
            `You wish to ${this.getObjectiveLabel(value)}`,
          required: true,
        },
        {
          name: 'companyRegistrationType',
          label: 'Is your idea/company formally registered?',
          placeholder:
            'Please choose the most appropriate objective for your business',
          component: CompanyTypeField,
          isCompanyField: true,
          value: 'No',
          active: false,
          submitted: false,
          submittedText: (value) => {
            if (value === 'No') {
              return `
                Your company is not registered
              `;
            } else {
              return `
                Your company is registered as ${value}
              `;
            }
          },
          required: true,
        },
        {
          name: 'company_sectors',
          label: 'Choose the sector that fits your company/ idea the best',
          placeholder: 'Find your sector',
          component: SectorField,
          value: null,
          componentProps: {
            searchable: true,
          },
          isCompanyField: true,
          active: false,
          submitted: false,
          submittedText: (value) =>
            `You work in ${value
              .map((id) => this.sectors.find((sector) => sector.id == id).name)
              .join(', ')}`,
          required: true,
        },
        {
          name: ['country', 'city'],
          label: 'Location',
          placeholder: 'Choose your country',
          component: LocationField,
          value: null,
          isCompanyField: true,
          active: false,
          submitted: false,
          submittedText: (value) => `You're in ${value.country}, ${value.city}`,
          required: true,
        },
        {
          name: 'referral_code',
          label: 'Referral Code',
          placeholder: 'Type here',
          component: ReferralCodeField,
          value: this.referralCode,
          active: true,
          submitted: false,
          isCompanyField: true,
          required: false,
        },
        {
          name: 'discoveredThrough',
          label: 'How did you discover Vibrantfounder?',
          placeholder: 'Select one',
          component: DiscoveryField,
          value: 'Search engine (google, Yahoo, etc)',
          isCompanyField: false,
          active: false,
          submitted: false,
          submittedText: (value) => `You discovered us through ${value}`,
          required: false,
          canShow: () => {
            return !this.userHasCompany;
          },
        },
      ],
      activeFieldIndex: 0,
      isCreatingCompany: false,
      isNewCompany: false,
      steps: [
        { step: 1, label: 'Step 1' },
        { step: 2, label: 'Step 2' },
        { step: 3, label: 'Step 3' },
      ],
      activeStep: 1,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('config', {
      businessObjectives: (state) => state.businessObjectives,
      businessStages: (state) => state.businessStages,
      departments: (state) => state.departments,
      sectors: (state) => state.businessSectors,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    fieldsFilled() {
      return this.fields.every((field) => field.value || !field.required);
    },
    userHasCompany() {
      return !!(
        (this.user.companies && this.user.companies.length) ||
        (this.user.teams && this.user.teams.length)
      );
    },
  },
  // beforeMount() {
  //   this.prefillReferralCode();
  //   console.log('Shix', this.referralCode);
  // },
  created() {
    this.isNewCompany = !!this.$route.query.new_company;
    // if (this.isNewCompany) {
    //   this.startOnboarding();
    // }

    this.prefillReferralCode();
    console.log('Shix', this.referralCode);

    // Update the `referral_code` field in `fields2` after setting the referralCode
    const referralField = this.fields2.find((f) => f.name === 'referral_code');
    if (referralField) {
      referralField.value = this.referralCode;
    }
  },
  methods: {
    ...mapActions({
      setActiveCompany: 'company/setActiveCompany',
      resetCompanyState: 'company/resetCompanyState',
      setUser: 'auth/setUser',
    }),
    handleReferralDetails(details) {
      this.referralDetails = details;
      console.log('in Parent==>', this.referralDetails);
    },
    prefillReferralCode() {
      // Get the referral code from localStorage if available
      const storedReferralCode = localStorage.getItem('referral_code');

      if (storedReferralCode) {
        this.referralCode = storedReferralCode;
      }
    },
    establishAffiliateConection(referrer, referredCompany) {
      try {
        const response = recordCompanyReferral({
          companyThatRefers: referrer,
          companyReferred: referredCompany,
        });
        console.log('Succexx', response);
        return { success: true, data: response.data };
      } catch (e) {
        console.log('Eroxx', e);
        return { success: false };
      }
    },
    goBackToPage() {
      // go back to previous page
      this.$router.go(-1);
    },
    startOnboarding() {
      this.activeStep++;
      this.animateToOnboarding = true;
      setTimeout(() => {
        this.hasStartedOnboarding = true;
        this.addEnterKeyListener();
      }, 550);
    },
    addEnterKeyListener() {
      document.addEventListener('keyup', this.onEnterKey);
    },
    removeEnterKeyListener() {
      document.removeEventListener('keyup', this.onEnterKey);
    },
    onEnterKey(e) {
      e.stopPropagation();
      if (e.key === 'Enter' || e.code === 'Enter') {
        if (this.activeFieldIndex === this.fields.length - 1) {
          this.onFormSubmit();
        } else if (this.fields[this.activeFieldIndex].value) {
          this.onNextClick(e, this.activeFieldIndex);
        }
      }
    },
    onFieldClick(field, index) {
      if (field.submitted) {
        this.goToField(this.activeFieldIndex, index);
        field.submitted = false;
      }
    },
    onNextClick(event, index) {
      event.stopPropagation();
      this.goToField(index, index + 1, true);
    },
    goToField(currentFieldIndex, nextFieldIndex, isNext = false) {
      if (isNext) {
        this.fields[currentFieldIndex].submitted = true;
      }
      this.fields.forEach((field, index) => {
        field.active = nextFieldIndex === index;
        if (field.active) {
          field.submitted = false;
        }
      });
      this.$refs['field' + nextFieldIndex][0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      this.activeFieldIndex = nextFieldIndex;
    },
    onFormSubmit() {
      this.isCreatingCompany = true;
      let companyForm = {};
      this.fields.forEach((field) => {
        if (field.name !== 'discoveredThrough') {
          if (typeof field.name === 'string') {
            companyForm[field.name] = field.value;
          } else {
            field.name.forEach((name) => {
              companyForm[name] = field.value[name];
            });
          }
        }
      });

      this.fields2
        .filter((fld) => fld.name !== 'referral_code')
        .forEach((field) => {
          if (field.name !== 'discoveredThrough') {
            if (typeof field.name === 'string') {
              companyForm[field.name] = field.value;
            } else {
              field.name.forEach((name) => {
                companyForm[name] = field.value[name];
              });
            }
          }
        });
      const teams = {
        data: {
          userId: this.user.id,
          role: 'ADMIN',
          department: companyForm.teams.department,
          position: companyForm.teams.role,
          isAccepted: true,
        },
      };
      const company_sectors = {
        data: companyForm.company_sectors.map((id) => {
          return {
            sectorId: id,
          };
        }),
      };
      companyForm = {
        ...companyForm,
        stageId: +companyForm.stageId,
        company_sectors,
        createdBy: this.user.id,
        teams,
      };

      createCompany(companyForm)
        .then(async (res) => {
          this.resetCompanyState(res.data.insert_company_one);
          const newCompanyId = res.data.insert_company_one.id;
          if (this.isNewCompany) {
            await this.setLastAccessedCompany({
              company: res.data.insert_company_one,
              user: this.user,
            });
          }
          if (!this.userHasCompany) {
            if (this.referralDetails) {
              const referralCompanyId = this.referralDetails[0].id;
              console.log('created nnew comp now==>');
              const response = this.establishAffiliateConection(
                referralCompanyId,
                newCompanyId
              );
              if (response.success) {
                localStorage.removeItem('referral_code');
                console.log('Sucexx', response.data);
              } else {
                console.log('Error occured==>');
              }
            }
            const field = this.fields.find(
              (field) => field.name === 'discoveredThrough'
            );
            try {
              await updateUser({
                id: this.user.id,
                discoveredThrough: field.value,
              });
            } catch (e) {
              console.log(e);
            }
          }
          setTimeout(() => {
            this.isCreatingCompany = false;
            this.$router.push({ path: '/app', query: { new_company: true } });
          }, 400);
        })
        .catch((e) => {
          this.isCreatingCompany = false;
          console.log({ e });
        });
    },
    getObjectives() {
      return this.businessObjectives;
    },
    getObjectiveLabel(value) {
      return this.businessObjectives.find(
        (objective) => objective.value === value
      ).description;
    },
    getStages() {
      console.log(this.businessStages);
      return this.businessStages;
    },
    getStageLabel(value) {
      return this.businessStages.find((stage) => stage.id == value).name;
    },
    setLastAccessedCompany({ company, user }) {
      return updateUser({
        id: user.id,
        set: { lastAccessedCompanyId: company.id },
      });
    },
  },
  beforeDestroy() {
    this.removeEnterKeyListener();
  },
};
</script>

<style lang="scss">
.onboarding {
  @apply flex flex-col h-full;

  // media queries for desktop and tablet
  @media (min-width: 768px) {
    &__sidebar {
      @apply flex items-center px-8 bg-transparent;
      height: 70px;
      &__message,
      &__info {
        @apply hidden;
      }
    }
  }

  @media (max-width: 767px) {
    &__sidebar {
      padding-left: 15px;
      margin-top: 20px;
      height: 70px;
    }

    .step_div {
      @apply hidden;
    }
    .onboarding__sideinfo {
      @apply hidden;
    }
  }

  &__content {
    scroll-behavior: smooth;
    @apply px-5;
    max-height: calc(100% - 70px);
    transition: padding 300ms;
    &--welcome {
      @apply flex-1 flex items-center;
    }
    &--started {
      @apply w-full;
    }
    .welcome {
      &__button {
        max-width: 100%;
      }
    }
  }
  &__logo {
    width: 15em;
    margin-bottom: 50px;
    &--white {
      @apply hidden;
    }
    &--orange {
      @apply block;
    }
  }
  &__form {
    @apply h-full w-full flex flex-col;
  }
  &__fields {
    @apply max-h-full w-full pt-16 overflow-y-scroll;
    &--complete {
      max-height: calc(100% - 80px);
    }
  }
  &__field {
    @apply my-5;
    &__header {
      @apply flex items-center;
    }
    &__index {
      @apply w-8 h-8 rounded-full flex items-center justify-center font-bold;
      border: 1px solid $color-grey;
      color: $color-grey;
      &--submitted {
        @apply border-none;
        background-color: #0fc694;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    &__label {
      @apply ml-5 flex-1;
    }
    &__content {
      @apply pl-4 flex;
      padding-top: 10px;
      padding-bottom: 10px;
      min-height: 4rem;
      transition: all 300ms;
      &__progress {
        @apply opacity-10 relative;
        width: 2px;
        height: inherit;
        background-color: $color-grey;
        &--next {
          @apply opacity-100;
          background: linear-gradient(
            180deg,
            #79889229 0%,
            #ef5323 65%,
            #3d44491a 100%
          );
        }
      }
      &__component {
        @apply ml-1 h-full w-full;
      }
    }
    &__next {
      @apply flex items-center absolute cursor-pointer;
      top: 50%;
      right: -2.85rem;
      transform: translateY(-50%);
      span {
        @apply ml-2 text-xs;
        color: $color-orange;
      }
      button {
        @apply flex items-center justify-center border-none;
        width: 20px;
        height: 20px;
        background-color: $color-orange;
        border: none;
        border-radius: 50%;
        svg {
          width: 10px;
          height: 9px;
        }
      }
    }
    &--active {
      .onboarding__field__index {
        border-color: $color-orange;
        color: $color-orange;
      }
    }
  }
  @screen lg {
    @apply flex-row;
    &__logo {
      margin-bottom: auto;
    }
    &__sidebar {
      @apply flex-col px-14 py-12 items-start relative;
      width: 35%;
      height: inherit;
      // background-image: linear-gradient(210deg, #1a86d0 0%, #103eba 100%);
      &__message,
      &__info {
        @apply flex;
        flex-direction: column;
      }
      &::after {
        content: '';
        // background-color: #56b5db;
        width: 20em;
        height: 20em;
        border-radius: 50%;
        opacity: 0.2;
        position: absolute;
        left: -50%;
        top: 50%;
        transform: translate(30%, -50%);
      }
    }
    &__content {
      @apply h-full;
      background: rgb(247, 246, 246);
      max-height: unset;
      &--welcome {
        @apply px-36;
      }
      &--started {
        @apply px-16;
      }
      .welcome {
        &__message {
        }
        &__button {
          max-width: 60%;
        }
      }
      // &__message {
      //   max-width: 100%;
      // }
    }
    &__logo {
      &--white {
        @apply block;
      }
      &--orange {
        @apply hidden;
      }
    }
    &__form {
      button {
        margin-left: auto;
      }
    }
  }
  @screen xl {
    &__content {
      &--welcome {
        @apply px-40;
      }
    }
  }
}

.step_div {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.active {
    .step_number {
      color: #ef5323;
      border: 2px solid #ef5323;
      font-weight: bold;
    }

    .step_text {
      color: #ef5323;
      font-weight: bold;
    }
  }

  .step_number {
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    border: 1px solid grey;
    font-weight: 600;
    color: grey;
    margin-right: 10px;
    border-radius: 50%;
  }
  .step_text {
    color: grey;
    font-weight: 600;
  }
}

.action_buttons {
  width: 100%;
  margin-bottom: 20px;
  @apply flex  justify-between;
}
</style>
